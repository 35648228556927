const url = 'https://9z4u5z97bj.execute-api.us-east-2.amazonaws.com';

/**
 * 查询
 */
export async function searchService(params) {
    const { $http } = window;
    const response = await $http.get(url, {params: params});

    return response;
}
