<!-- 查询 -->
<template>
    <div class="search-container">
        <div class="left">
            <el-input
                v-model="keywordLocal"
                type="textarea"
                :autosize="{ minRows: 8}"
                maxlength="300"
                showWordLimit
                placeholder="Please enter a sentence in English."
            />
            <el-button
                type="primary"
                size="small"
                class="submit-btn"
                @click="search"
            >MetaPro</el-button>
        </div>
        <div class="right">
            <div
                v-for="row in resultConfig"
                :key="row.label"
                class="result-item"
            >
                <div class="label">{{ row.label }}</div>
                <div class="text-container">
                    <div class="row">
                        {{ row.value }}
                    </div>
                    <i v-if="row.value" class="el-icon-copy-document copy-icon" @click="copy(row.value)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        keyword: {
            type: String,
            default: '',
        },
    },
    data () {
        return {

        };
    },
    computed: {
        resultConfig() {
            return [
                { label: 'Metaphor Identification:', value: this.list[1]},
                { label: 'Metaphor Interpretation:', value: this.list[2]},
                { label: 'Conceptualization:', value: this.list[3]},
                { label: 'Concept Mapping:', value: this.list[4]},
            ];
        },
        keywordLocal: {
            get() {
                return this.keyword;
            },
            set(val) {
                this.$emit('update:keyword', val);
            },
        },
    },

    created() {},

    methods: {
        search() {
            if (!this.keywordLocal) {
                this.$message.warning('Please enter a sentence in English.');
                return;
            }
            this.$emit('search', this.keywordLocal);
        },
        copy(text) {
            this.$copyText(text).then( e => {
                this.$message.success('copy success');
            }, (e) => {
                this.$message.error('copy fail');
            });
        },
    },
};

</script>
<style lang='less' scoped>
.search-container {
    display: flex;
    .left {
        width: 50%;
        margin-right: 40px;
        .submit-btn {
            float: right;
            margin-top: 12px;
        }
        /deep/ .el-textarea__inner {
            font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
        }
    }
    .right {
        width: 50%;
        .result-item {
            margin-bottom: 24px;
            .label {
                margin-bottom: 8px;
            }
            .text-container {
                min-height: 24px;
                padding: 12px 20px;
                background-color: #f5f7fa;
                border-color: #e4e7ed;
                color: #c0c4cc;
                position: relative;
                .row {
                    color: #303133;
                    line-height: 24px;
                    font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
                    &:not(:first-of-type) {
                        padding-left: 24px;
                    }
                }
                .copy-icon {
                    position: absolute;
                    bottom: 16px;
                    right: 16px;
                    cursor: pointer;
                }
            }
        }

    }
}
</style>