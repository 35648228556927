<!-- api关闭状态 -->
<template>
    <div class="close-container">
        <div
            v-for="citationItem in CitationConfig"
            :key="citationItem.label"
            class="citation-item"
        >
            <div class="label">{{ citationItem.label }}</div>
            <div class="text-container">
                <div
                    v-for="(row, idx) in citationItem.rows"
                    :key="idx"
                    class="row"
                >
                    {{ row }}
                </div>
                <i class="el-icon-copy-document copy-icon" @click="copy(citationItem.rows.join('\n'))"/>
            </div>
        </div>
    </div>
</template>

<script>
// import CitationConfig from '@/static/CitationConfig.json';
import axios from 'axios';

export default {
    data () {
        // this.CitationConfig = CitationConfig;
        return {
            CitationConfig: [],
        };
    },

    components: {},

    computed: {},

    async created() {
        const { data } = await axios({
            url: '/static/CitationConfig.json', // url路径是相对于index.html文件的
            method: 'get',
        });

        this.CitationConfig = data;
    },

    methods: {
        copy(text) {
            this.$copyText(text).then( e => {
                this.$message.success('copy success');
            }, (e) => {
                this.$message.error('copy fail');
            });
        },
    },
};

</script>
<style lang='less' scoped>
.close-container {
    font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
    .citation-item {
        .label {
            font-size: 14px;
            color: #606266;
            line-height: 40px;
        }
        .text-container {
            padding: 20px;
            background-color: #f5f7fa;
            border-color: #e4e7ed;
            color: #c0c4cc;
            position: relative;
            .row {
                color: #303133;
                line-height: 24px;
                font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
                word-break: break-all;
                &:not(:first-of-type) {
                    padding-left: 24px;
                }
            }
            .copy-icon {
                position: absolute;
                bottom: 16px;
                right: 16px;
                cursor: pointer;
            }
        }
    }
}
</style>