<!-- 首页 -->
<template>
    <div v-loading="loading" class="user-manage-container">
        <div v-if="!isApiClosed" class="header">
            <el-tabs v-model="avtiveTab">
                <el-tab-pane
                    v-for="item in TAB_LIST"
                    :key="item.value"
                    :label="item.label"
                    :name="item.value"
                />
            </el-tabs>
        </div>
        <div class="main">
            <template v-if="isApiClosed">
                <h2 class="close-tips">TEMPORARITLY CLOSED</h2>
                <Citation/>
            </template>
            <template v-else>
                <Search
                    v-show="avtiveTab === 'Text'"
                    :keyword.sync="keyword"
                    :loading="loading"
                    :list="resultList"
                    @search="search"/>
                <Citation v-show="avtiveTab === 'Citation'"/>
            </template>
        </div>
    </div>
</template>

<script>
import * as api from './service';
import Search from './components/search.vue';
import Citation from './components/citation.vue';

const TAB_LIST = [
    { label: 'Text', value: 'Text'},
    { label: 'Citation', value: 'Citation'},
];

export default {
    data () {
        this.TAB_LIST = TAB_LIST;
        return {
            avtiveTab: 'Text',
            keyword: '',
            loading: false,
            resultList: [],
            isApiClosed: false,
            count: 0,
        };
    },

    components: {
        Search,
        Citation,
    },

    computed: {},

    async created() {
        await this.search('hello', true);
    },

    methods: {
        setApiStatus(status) {
            if (status === 200) {
                this.isApiClosed = false;
            } else { // api关闭
                this.isApiClosed = true;
            }
        },
        // apiStatusFlag为true时表示检查api状态
        async search(keyword = this.keyword, apiStatusFlag = false) {
            try {
                this.loading = true;
                const {status, data} = await api.searchService({q: keyword});

                if (apiStatusFlag) {
                    this.setApiStatus(status); // api状态检测
                    this.loading = false;
                    return;
                }
                if (status === 200) {
                    if (Array.isArray(data)) { // 正常返回
                        this.resultList = data;
                        this.count = 0;
                        this.loading = false;
                    } else if (data === null) { // 服务器繁忙，重试16次
                        if (this.count <= 15) {
                            this.search();
                            this.count++;
                        } else {
                            this.$notify({
                                title: 'Error',
                                message: 'fail',
                                type: 'error',
                            });
                            this.count = 0;
                            this.loading = false;
                        }
                    } else { // 其他错误
                        this.loading = false;
                        this.$notify({
                            title: 'Error',
                            message: data,
                            type: 'error',
                        });
                    }
                }
            } finally {
                // this.loading = false;
            }
        },

    },
};

</script>
<style lang='less' scoped>
.user-manage-container {
    .header {
        margin-bottom: 20px;
    }
    .main {
        .close-tips {
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 50px 0;
        }
    }
}
</style>